import React, { PureComponent } from 'react';

import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';

import {
  DynamicInfoArea,
  DynamicInfoHeader,
  DynamicInfoContent, InfoHeader
} from '../atomicStyledComponents/stylesIndex';
import { makeGetInputStateByField } from '../../../redux/selectors/inputSelectors';

class DynamicInfoBox extends PureComponent {
  constructor(props) {
    super(props);
    this.title = props.title;
    this.state = {
      value: props.value,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.loaded) {
      return { loaded: nextProps.loaded }};
    return null;
  }

  insertMarkup = _ => {
    return { __html: this.state.value }
  }

  render() {
    return (
      <DynamicInfoArea>
        <DynamicInfoHeader>
          { this.title }
        </DynamicInfoHeader>
        <DynamicInfoContent dangerouslySetInnerHTML={ this.insertMarkup() } />
      </DynamicInfoArea> )
  }
}

const makeMapStateToProps = (_) => {
  const getTextValueFromState = makeGetInputStateByField();

  return (state, props) => {
    let newProps = {};

    // We load the dynamic value from the state
    const textVal = getTextValueFromState(state, props);
    if (textVal.length > 0) {
      const input = textVal[0];
      const { value } = input;
      if (!isEqual(props.value, value)) {
        newProps = { ...newProps, value };
      }
    }
    return Object.keys(newProps).length > 0 ? newProps : props;
  };
};

export default connect(makeMapStateToProps, null, null, {
  pure: true,
  areStatePropsEqual: (next, prev) => {
    return isEqual(next, prev);
  },
})(DynamicInfoBox);
